export default ({ identifier, optionsTextKey, optionsValueKey, options, selected, disabled }) => ({
  identifier: identifier,
  optionsTextKey: optionsTextKey,
  optionsValueKey: optionsValueKey,
  options: options,
  selected: selected,
  disabled: disabled,

  loadingComponent: true,

  init() {
    this.loadingComponent = false

    this.$watch('selected', (option) => {
      this.$wire.call('onChange', option)
    })
  },
})
