//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

export default ({ identifier }) => ({
  identifier: identifier,
  show: false,
  month: '',
  year: '',
  numOfDays: [],
  blankDays: [],
  timeIntervals: [],
  daysArr: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  monthNamesArr: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  monthShortNamesArr: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  loadingComponent: true,

  init() {
    this.initDate()
    this.getNoOfDays()
  },

  initDate() {
    this.timeIntervals = this.createIntervals(['00', '30'])

    // console.log('selectedDate', this.$wire.selectedDate)
    // console.log('selectedTime', this.$wire.selectedTime)

    this.month = new Date(this.$wire.selectedDate).getMonth()
    this.year = new Date(this.$wire.selectedDate).getFullYear()

    this.disabled = this.$wire.disabled

    this.setTime(this.$wire.selectedTime)

    this.$watch('show', (value) => {
      if (value) {
        this.scrollToSelectedTime()
      }
    })
  },

  openDatePicker() {
    if (!this.disabled) {
      this.show = !this.show
    }
  },

  isSelectedDate(day) {
    const d = `${this.year}-${(this.month + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
    return this.$wire.selectedDate === d ? true : false
  },

  isToday(day) {
    const today = new Date()
    const d = new Date(this.year, this.month, day)
    return today.toDateString() === d.toDateString() ? true : false
  },

  getNoOfDays() {
    let daysInMonth = new Date(this.year, this.month + 1, 0).getDate()
    // find where to start calendar day of week
    let dayOfWeek = new Date(this.year, this.month).getDay()
    let blankDaysArray = []
    for (var i = 1; i <= dayOfWeek; i++) {
      blankDaysArray.push(i)
    }
    let daysArray = []
    for (var i = 1; i <= daysInMonth; i++) {
      daysArray.push(i)
    }
    this.blankDays = blankDaysArray
    this.numOfDays = daysArray
  },

  setDate(day) {
    const date = `${this.year}-${(this.month + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
    this.$wire.setSelectedDate(date)
    this.isSelectedDate(day)
    this.show = false
  },

  setTime(interval) {
    this.$wire.setSelectedTime(interval)
  },

  createIntervals(intervals) {
    let hourMins = []
    for (var i = 0; i < 24; i++) {
      let twoDigitHour = ('0' + i).slice(-2)
      for (let j = 0; j < intervals.length; j++) {
        hourMins.push(`${twoDigitHour}:${intervals[j]}`)
      }
    }
    return hourMins
  },

  scrollToSelectedTime() {
    setTimeout(() => {
      const scrollContainerEl = this.$refs.timeScrollContainer
      const selectedTimeIndex = this.timeIntervals.findIndex((v) => v == this.$wire.selectedTime)
      const selectedEl = document.getElementById(`timeListItem_${identifier}_${selectedTimeIndex}`)
      const selectedElOffset = selectedEl.offsetTop
      this.scrollTo(scrollContainerEl, selectedElOffset - 80, 600)
    }, 50)
  },

  scrollTo(element, to, duration) {
    let start = element.scrollTop
    let change = to - start
    let currentTime = 0
    let increment = 20

    let animateScroll = () => {
      currentTime += increment
      var val = Math.easeInOutQuad(currentTime, start, change, duration)
      element.scrollTop = val
      if (currentTime < duration) {
        setTimeout(animateScroll, increment)
      }
    }
    animateScroll()
  },
})
