import { cloneDeep } from 'lodash'

export default ({ identifier, steps, namespaces }) => ({
  identifier: identifier,
  steps: steps,
  optionsMenu: false,
  namespaces: namespaces,

  init() {
    // console.log('init', this.steps)
    // this.$watch('selected', () => {
    // this.$wire.call('onChange', this.selected)
    // })
    this.tempSteps = this.steps
  },

  toggleOptionsMenu() {
    this.optionsMenu = !this.optionsMenu
  },
})
