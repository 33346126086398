import Alpine from 'alpinejs';

import baseAutomationWorkflowSteps from './alpine/automation-workflow-steps.js';
import baseDateTimePicker from './alpine/datetime-picker.js';
import baseRadioGroup from './alpine/radio-group.js';
import baseSelect from './alpine/select.js';

import persist from '@alpinejs/persist';
import Echo from 'laravel-echo';

import hljs from 'highlight.js/lib/core';
import xml from 'highlight.js/lib/languages/xml';
import javascript from 'highlight.js/lib/languages/javascript';
import json from 'highlight.js/lib/languages/json';

import Pusher from 'pusher-js';

import * as templates from './templates';
import { orderBy } from 'lodash';
import select from './alpine/select.js';

window.branded_template = templates.branded_template;

hljs.registerLanguage('xml', xml)
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('json', json);
window.hljs = hljs

// window.onAvailable = (variable, callback) => {
//   function checkVariableIsAvailable(variable, callback) {
//     if (typeof window[`${variable}`] === 'undefined') {
//       setTimeout(function () {
//         checkVariableIsAvailable(variable, callback)
//       }, 1)
//     } else {
//       callback()
//     }
//   }
//   checkVariableIsAvailable(variable, callback)
// }

window._ = require('lodash')
window.io = require('socket.io-client')
window.axios = require('axios')
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.headers.common['Accept'] = 'application/json';
window.axios.defaults.headers.common['Content-Type'] = 'application/json';

let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

if (typeof window.pusher !== 'undefined') {
  window.Pusher = Pusher
  window.Echo = new Echo({
    auth: {
      headers: {
        'X-CSRF-TOKEN': csrfToken,
      },
    },
    csrfToken: csrfToken,
    authEndpoint: `/broadcasting/auth`,
    host: window.location.hostname == 'commpass.localhost' ? 'http://commpass.localhost:6001' : window.location.protocol + "//" +window.location.hostname,
    transports: ['websocket', 'polling', 'flashsocket'],
    //broadcaster: 'pusher',
    //key: import.meta.env.VITE_PUSHER_APP_KEY,
    //cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    //forceTLS: false,
    //encrypted: true,
    //disableStats: true,
  });
}
Alpine.plugin(persist)

Alpine.store('toasts', {
  counter: 0,
  list: [],

  createToast(message, type = 'info') {
    let id = this.generateUUID()

    this.list.push({
      id: id,
      message,
      type,
      visible: true,
    })

    this.destroyToastDelay(id)
  },

  destroyToast(id) {
    const findIndex = this.list.findIndex((o) => o.id == id)

    if (findIndex > -1) {
      this.list[findIndex].visible = false
      setTimeout(() => {
        this.list.splice(findIndex, 1)
        localStorage.setItem('_x_toastsList', JSON.stringify(this.list))
      }, 300)
    }
  },

  destroyToastDelay(id) {
    setTimeout(() => {
      this.destroyToast(id)
    }, 2000 * this.totalVisible)
  },

  generateUUID() {
    let d = new Date().getTime()
    let d2 = (performance && performance.now && performance.now() * 1000) || 0

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16
      if (d > 0) {
        r = (d + r) % 16 | 0
        d = Math.floor(d / 16)
      } else {
        r = (d2 + r) % 16 | 0
        d2 = Math.floor(d2 / 16)
      }
      return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16)
    })
  },

  get totalVisible() {
    return (
      this.list.filter((toast) => {
        return toast.visible
      }).length + 1
    )
  },
})

Alpine.store('editor', {
  activeTab: 'sendsuite.emails.create.settings',
  loading: true,
  template: null,
  templateList: [
    {
      id: 1,
      name: 'Blank',
      icon: null,
      subject: null,
      definition: templates.default_template,
      tags: ['default', 'blank'],
    },
    {
      id: 2,
      name: 'Branded',
      icon: null,
      subject: null,
      definition: templates.branded_template,
      tags: ['default', 'blank'],
    },
    {
      id: 3,
      name: 'Ask for a referral from clients',
      icon: null,
      subject: 'Do you know anyone who needs our help?',
      definition: templates.ask_for_referral_from_clients,
      tags: ['email templates'],
    },
    {
      id: 4,
      name: 'Improve your profitability',
      icon: null,
      subject: 'How to improve your profitability',
      definition: templates.improve_your_profitability,
      tags: ['email templates'],
    },
    {
      id: 5,
      name: 'Ask for a Facebook recommendation',
      icon: null,
      subject: 'Would you mind recommending us on Facebook?',
      definition: templates.ask_for_a_facebook_recommendation,
      tags: ['email templates'],
    },
    {
      id: 6,
      name: 'Ask for a Google review',
      icon: null,
      subject: 'Would you mind reviewing us on Google?',
      definition: templates.ask_for_a_google_review,
      tags: ['email templates'],
    },
  ],
  setTemplate(id) {
    this.template = this.templateList.find((x) => x.id == id)
  },
  setActiveTab(val) {
    Livewire.emit('onChangeCreateEmailActiveTab', val)
    this.activeTab = val
  },
});

Alpine.store('editBlock', {
  show: false,
  post_type: 'business',
  dialogTitle: 'Edit Business Block',
  posts:[],
  topic: 'all',
  search: '',
  region: 'any',
  orderBy: 'date',
  order: 'desc',
  loadingArticles: false,
  select_posts_html: '',

  init(){
    this.load_posts();
  },
  selectPost(id){
    if(document.getElementById('bordered-checkbox-'+id).checked){
      this.posts.forEach(post => {
        if(post.id == id){
          post.selected = true;
        }
      });
    }
    else{
      this.posts.forEach(post => {
        if(post.id == id){
          post.selected = false;
        }
      });
    }
  },
  load_posts(){
    console.log('Loading Posts');
    this.loadingArticles = true;
    axios.get('/api/content/business',{
      params: {
        ...(this.search && { search: this.search }),
        ...((this.topic && this.topic != 'all') && { topics: this.topic }),
        regions: this.region,
        orderBy: this.orderBy,
        order: this.order,
        count: 5
      }
    })
    .then(res => {
        this.posts = this.posts.filter(post => post?.selected == true);
        let newdata = res.data.filter(post => !this.posts.some(p => p.id === post.id));
        this.posts.push(...newdata);
    })
    .finally(() => {
      this.showArticleSelection();
      this.loadingArticles = false;
    })
    .catch(e => {
      console.log("Error:", e);
    });
  },
  showArticleSelection(){
    this.loadingArticles = false;
    this.select_posts_html = '';

    if(this.posts == null || this.posts.length == 0){
      this.select_posts_html = `<div class="text-center w-full text-gray-darkest">No Articles Found</div>`;
      return;
    }
    this.posts.forEach(post => {
      this.select_posts_html += this.getArticleSlector(post.id,post.title,post.selected ? true : false);
    });
  },
  getArticleSlector(id,name,checked = false){
    return `<div data-id='`+id+`' class="flex mb-2 items-center border border-gray text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <input x-on:change="$store.editBlock.selectPost(`+id+`)" `+(checked?'checked':'')+` id="bordered-checkbox-`+id+`" type="checkbox" value="post_title" name="article-checkbox-`+id+`" class="m-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
              <label for="bordered-checkbox-`+id+`" class="w-full py-2 text-sm font-medium text-gray-100 dark:text-gray-300">`+name+`</label>
            </div>`;
  },
  save(){
    this.posts = this.posts.filter(post => post?.selected == true);
    this.toggle();
  },
  toggle() {
    this.show = !this.show;
  }
});

Alpine.data('baseSelect', baseSelect)
Alpine.data('baseRadioGroup', baseRadioGroup)
Alpine.data('baseDateTimePicker', baseDateTimePicker)
Alpine.data('baseAutomationWorkflowSteps', baseAutomationWorkflowSteps)

window.Alpine = Alpine

Alpine.start()

window.addEventListener('toast-message-show', (event) => {
  Alpine.store('toasts').createToast(event.detail.message, event.detail.type)
})
