export default ({ identifier, optionsTextKey, optionsValueKey, options, selected, multiple, removable, disabled, placeholder, hint }) => ({
  identifier: identifier,
  optionsTextKey: optionsTextKey,
  optionsValueKey: optionsValueKey,
  options: options,
  selected: selected,
  multiple: multiple,
  removable: removable,
  disabled: disabled,
  placeholder: placeholder,
  hint: hint,
  show: false,
  search: '',

  init() {
    this.$watch('selected', () => {
      this.$wire.call('onChange', this.selected)
    })
  },

  open() {
    if (!this.disabled) {
      this.show = true
    }
  },

  close() {
    this.show = false
  },

  isOpen() {
    return this.show === true
  },

  select(option, event) {
    if (!this.disabled) {
      if (!option.disabled) {
        if (this.multiple) {
          // check if value already in selected array
          const isSelected = this.selected.find((o) => o == option[this.optionsValueKey]) ? true : false

          // find index in selected array (chips)
          const findSelectedIndex = this.selected.findIndex((o) => o == option[this.optionsValueKey])

          if (isSelected && findSelectedIndex > -1) {
            this.selected.splice(findSelectedIndex, 1)
          } else {
            this.selected.push(option[this.optionsValueKey])
          }
        } else {
          this.selected = option[this.optionsValueKey]
          this.show = false
        }
      }
    }
  },

  remove(option) {
    if (!this.disabled) {
      if (this.multiple) {
        const findSelectedIndex = this.selected.findIndex((o) => o == option[this.optionsValueKey])

        if (findSelectedIndex > -1) {
          this.selected.splice(findSelectedIndex, 1)
        }
      } else {
        this.selected = null
      }
    }
  },

  selectedChips() {
    if (this.multiple) {
      return this.options.filter((o) => this.selected.includes(o[this.optionsValueKey]))
    } else {
      return this.options.filter((o) => [this.selected].includes(o[this.optionsValueKey]))
    }
  },

  optionSelected(optionValue) {
    if (this.multiple) {
      return this.selected.includes(optionValue)
    } else {
      return [this.selected].includes(optionValue)
    }
  },
})
